import BaseService from "../base/base.service";
import axios from 'axios';

class ItemLastIncomeService extends BaseService {
    get modelName() {
        return "ItemLastIncome"
    }

    get(data) {
        let params = {
            id: data.itemId,
            warehouse: data.warehouseId
        }
        return axios.get(this.getSingleUrl(data.itemId), {params: params}).then((response) => {
            let PurchaseItem = response.data.PurchaseItem;
            let Craft = response.data.Craft;
            let TransferItem = response.data.TransferItem;
            return {
                'PurchaseItem': PurchaseItem,
                'Craft': Craft,
                'TransferItem': TransferItem
            };
        }, (error) => {
            return Promise.reject(error);
        })
    }
}

export default new ItemLastIncomeService()
