<template>
    <div class="d-flex">
        <div>{{ translation.translate("frequentlyRepeated", "senaprixoda(posledniy)") }}:</div>
        <div v-if="itemLastPurchasePrice" class="d-flex ml-1">
            {{
                itemLastPurchasePrice.value | division(itemLastPurchasePrice.currency) | decimal
            }}
            <div v-if="itemLastPurchasePrice.currency">
                <div class="ml-1">{{ itemLastPurchasePrice.currency.Name }}</div>
            </div>
            <div v-else-if="selectedCurrency">
                {{ selectedCurrency.Name }}
            </div>
        </div>
        <div v-else class="ml-1">
            {{ translation.translate("frequentlyRepeated", "NeUkazano") }}
        </div>
    </div>
</template>

<script>
import translation from "../../translation/translate";
import {mapGetters} from "vuex";
import ItemLastIncomeService from "../../services/purchase/item.last.income.service";

export default {
    name: "ItemCostPriceComponent",
    data() {
        return {
            translation,
            itemLastPurchasePrice: null
        }
    },
    props: {
        WarehouseItem: {
            type: Object,
            default: {}
        }
    },
    computed: {
        ...mapGetters({
            selectedCurrency: 'selectedCurrency'
        })
    },
    methods: {
        viewCostPrice() {
            let vm = this;
            if (vm.WarehouseItem && vm.WarehouseItem.Item) {
                let data = {
                    itemId: vm.WarehouseItem.Item.ItemId,
                    warehouseId: vm.WarehouseItem.Warehouse.WarehouseId
                }
                ItemLastIncomeService.get(data).then(function (response) {
                    vm.itemLastPurchasePrice = null;
                    if (!vm.isCheckedViewCostPrice) {
                        vm.$root.$emit('bv::show::tooltip', 'sale-item-price-trigger-id');
                    }
                    if (response.PurchaseItem && response.Craft) {
                        if (response.PurchaseItem.CreatedAt > response.Craft.CreatedAt) {
                            vm.itemLastPurchasePrice = {
                                value: response.PurchaseItem.PriceCurrencyValue.Value,
                                currency: response.PurchaseItem.PriceCurrencyValue.Currency || null
                            }
                        } else {
                            vm.itemLastPurchasePrice = {
                                value: response.Craft.Price,
                                currency: null
                            }
                        }
                    } else if (response.PurchaseItem) {
                        vm.itemLastPurchasePrice = {
                            value: response.PurchaseItem.PriceCurrencyValue.Value,
                            currency: response.PurchaseItem.PriceCurrencyValue.Currency || null
                        }
                    } else if (response.Craft) {
                        vm.itemLastPurchasePrice = {
                            value: response.Craft.Price,
                            currency: null
                        }
                    } else if (response.TransferItem) {
                        vm.itemLastPurchasePrice = {
                            value: response.TransferItem.PriceCurrencyValue.Value,
                            currency: response.TransferItem.PriceCurrencyValue.Currency || null
                        }
                    } else if (vm.WarehouseItem.LastPurchasePriceCurrencyValue) {
                        vm.itemLastPurchasePrice = {
                            value: vm.WarehouseItem.LastPurchasePriceCurrencyValue.Value,
                            currency: vm.WarehouseItem.LastPurchasePriceCurrencyValue.Currency || null
                        }
                    }
                });
            }
        },
    },
    mounted() {
        this.viewCostPrice();
    },
    watch: {
        'WarehouseItem'(newValue) {
            if (newValue)
                this.viewCostPrice();
        }
    }
}
</script>

<style scoped>

</style>
