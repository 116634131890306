<template>
    <b-modal
        centered
        size="lg"
        :title="title"
        id="manual-add-modal"
        hide-header-close
        v-model="isShown"
    >
        <template #modal-header>
            <h5>{{ title }}</h5>
            <b-button-close @click="onCancel"/>
        </template>
        <template #default>
            <b-row>
                <b-col cols="12" lg="12">
                    <label>Название товара</label>
                    <WarehouseItemSearchElement ref="WarehouseItemSearchElement" v-model="NewItem.Item"/>
                    <template v-if="$v.NewItem.Item.$error">
                        <small
                            class="text-danger"
                            v-if="!$v.NewItem.Item.required"
                        >Выберите товар</small>
                    </template>
                </b-col>
                <b-col cols="12" lg="6">
                    <label>Цена</label>
                    <b-input-group>
                        <IMaskPriceInput v-model="NewItem.PriceCurrencyValue.Value"
                                         :class="{ 'is-invalid': $v.NewItem.PriceCurrencyValue.Value.$error }"
                                         @focus="() => { if (!NewItem.PriceCurrencyValue.Value) NewItem.PriceCurrencyValue.Value = ''}"
                                         @blur="() => { if (!NewItem.PriceCurrencyValue.Value ) NewItem.PriceCurrencyValue.Value = 0}"/>
                        <AppendCurrencyToInput ref="AppendCurrencyToInput" :is-append="true"
                                               :price-currency-value="NewItem.PriceCurrencyValue"/>
                        <template v-if="$v.NewItem.PriceCurrencyValue.Value.$error">
                            <small
                                class="text-danger"
                                v-if="!$v.NewItem.PriceCurrencyValue.Value.required"
                            >Введите цена</small>
                        </template>
                    </b-input-group>
                    <div v-if="isCheckedViewCostPrice && isAllowedViewProductPurchasePrice" class="text-success">
                        <ItemCostPriceComponent ref="ItemCostPriceComponent"
                                                :warehouse-item="NewItem ? NewItem.Item : {}"/>
                    </div>
                </b-col>
                <b-col cols="12" lg="6">
                    <label>Количество</label>
                    <b-form-input
                        type="number"
                        ref="quantity"
                        v-model="NewItem.Quantity"
                        @keyup.enter="addItem(NewItem)"
                    ></b-form-input>
                    <template v-if="$v.NewItem.Quantity.$error">
                        <small
                            class="text-danger"
                            v-if="!$v.NewItem.Quantity.required"
                        >Введите количество</small>
                    </template>
                </b-col>
                <b-col cols="12" v-if="isWeighingProduct">
                    <label>Сумма</label>
                    <b-input-group>
                        <IMaskPriceInput v-model="Total"
                                         @keyupEnter="addItem(NewItem)"
                                         @input="calculateQuantityByTotal" ref="WeighingProductTotal"/>
                    </b-input-group>
                </b-col>
                <b-col cols="12" class="my-2" v-if="NewItem.Item && NewItem.Item.StateParts.length > 0">
                    <label>Детали остатки</label>
                    <b-input-group>
                        <v-select :options="NewItem.Item.StateParts" @input="onSelectedStateParts" label="Name"
                                  class="w-100" multiple placeholder="Выберите остаток">
                            <template slot="option" slot-scope="scope">
                                <div class="p-0 m-0">
                                    <span class="mr-2">{{ scope.Name }}</span>
                                    <b-badge variant="success">
                                        {{ scope.State | decimal }}
                                    </b-badge>
                                </div>
                            </template>
                            <template slot="selected-option" slot-scope="scope">
                                <span class="mr-2">{{ scope.Name }}</span>
                                <b-badge>
                                    {{ scope.State | decimal }}
                                </b-badge>
                            </template>
                        </v-select>
                    </b-input-group>
                </b-col>
                <b-col cols="12" xl="12" class="my-2" v-if="NewItem.Item && NewItem.Item.StateParts.length > 0">
                    <template v-if="NewItem.StatePartOperations.length > 0">
                        <b-row>
                            <b-col cols="12" xl="6" v-for="operation in NewItem.StatePartOperations"
                                   :key="operation.Id">
                                <label>{{ operation.Name }} количество</label>
                                <b-input-group>
                                    <b-form-input v-model="operation.Quantity"/>
                                </b-input-group>
                            </b-col>
                        </b-row>
                    </template>
                </b-col>
            </b-row>
        </template>
        <template #modal-footer="{ cancel }">
            <b-button
                pill
                @click="addItem(NewItem)"
                class="border-0 my-primary"
                :disabled="isSubmitted"
            >
                <b-spinner v-if="isSubmitted" small></b-spinner>
                Добавить
            </b-button>
            <b-button @click="onCancel" pill variant="outline-secondary">
                Отмена
            </b-button>
        </template>
    </b-modal>
</template>

<script>
import {validationMixin} from "vuelidate";
import WarehouseItemSearchElement from "../searchElements/WarehouseItemSearchElement";
import AppendCurrencyToInput from "../currency/AppendCurrencyToInput";
import IMaskPriceInput from "../imask/IMaskPriceInput";

const {required} = require("vuelidate/lib/validators")
import {debounce} from 'underscore';
import {mapGetters} from "vuex";
import ItemCostPriceComponent from "../additional/ItemCostPriceComponent";

let weights = ["kg", 'кг', 'kilo', 'kilogram', 'килограм', 'gram', 'грам', 'tonna', 'тонна']
import math from "../../plugins/math";

export default {
    name: 'ManualAddItemModal',
    components: {ItemCostPriceComponent, IMaskPriceInput, AppendCurrencyToInput, WarehouseItemSearchElement},
    props: {
        title: {
            default: '',
            type: String
        }
    },
    data() {
        return {
            Total: '',
            NewItem: {
                PriceCurrencyValue: {
                    Currency: null,
                    CurrencyId: null,
                    CurrencyValueId: null,
                    Rate: null,
                    SystemValue: 0,
                    Value: 0,
                },
                Quantity: '',
                Item: null,
                DiscountCurrencyValue: {
                    Currency: null,
                    CurrencyId: null,
                    CurrencyValueId: null,
                    Rate: null,
                    Value: 0
                },
                StatePartOperations: [{
                    Id: '',
                    Name: '',
                    Quantity: 0
                }]
            },
            isSubmitted: false,
            isShown: false
        }
    },
    validations: {
        NewItem: {
            Item: {
                required
            },
            PriceCurrencyValue: {
                Value: {
                    required
                }
            },
            Quantity: {
                required
            }
        }
    },
    mixins: [validationMixin],
    computed: {
        ...mapGetters({
            currencies: 'getAllCurrencies',
            isCheckedViewCostPrice: "getCheckedViewCostPrice",
            isAllowedViewProductPurchasePrice: "isAllowedViewProductPurchasePrice",
            isCheckedSaleWithoutCurrency: "getCheckedSaleWithoutCurrency",
        }),
        isWeighingProduct() {
            if (this.NewItem.Item)
                return weights.find(weight => {
                    return this.NewItem.Item.Item.Measurement.toLocaleLowerCase() === weight;
                });
        }
    },
    methods: {
        calculateQuantityByTotal: debounce(function () {
            this.NewItem.Quantity = Math.round10(+this.Total / +this.NewItem.PriceCurrencyValue.Value, -4);
        }, 500),
        onCancel() {
            this.resetSelectedItem();
            this.$bvModal.hide('manual-add-modal');
        },
        onSelectedStateParts(selectedStatePart) {
            this.NewItem.StatePartOperations = selectedStatePart.map(part => {
                return {
                    Id: part.Id,
                    Name: part.Name,
                    Quantity: part.State >= this.NewItem.Quantity ? this.NewItem.Quantity : part.State
                }
            });
        },
        clear() {
            this.resetSelectedItem()
            this.$v.NewItem.$reset();
            this.focus();
            this.isSubmitted = false;
            this.Total = '';
        },
        resetSelectedItem() {
            this.NewItem = {
                Item: null,
                PriceCurrencyValue: {
                    Currency: null,
                    CurrencyId: null,
                    CurrencyValueId: null,
                    Rate: null,
					SystemValue: 0,
                    Value: 0
                },
                Quantity: null,
                DiscountCurrencyValue: {
                    Currency: null,
                    CurrencyId: null,
                    CurrencyValueId: null,
                    Rate: null,
                    Value: 0
                },
                StatePartOperations: [{
                    Id: '',
                    Name: '',
                    Quantity: 0
                }]
            }
        },
        addItem(NewItem) {
            let vm = this;
            vm.$v.$touch();
            if (!vm.$v.NewItem.$invalid) {
                this.isSubmitted = true;
                this.$emit('addProduct', NewItem);
            }
        },
        focus() {
            if (this.$refs.WarehouseItemSearchElement)
                this.$refs.WarehouseItemSearchElement.focus();
        },
        setSubmittedValue(isSubmitted) {
            this.isSubmitted = isSubmitted;
        },
        onChangeNewItemProduct(newV) {
            let currentPriceCurrency = null;
            if (this.currencies.length > 0 && newV.Item.PriceCurrencyValue && newV.Item.PriceCurrencyValue.CurrencyId)
                currentPriceCurrency = this.currencies.find(currency => currency.CurrencyId === newV.Item.PriceCurrencyValue.CurrencyId);
            if (newV.Item.PriceCurrencyValue.Currency && this.isCheckedSaleWithoutCurrency) {
                this.NewItem.PriceCurrencyValue.Currency = null;
                this.NewItem.PriceCurrencyValue.CurrencyId = null;
                this.NewItem.PriceCurrencyValue.CurrencyValueId = null;
                this.NewItem.PriceCurrencyValue.Rate = null;
				this.NewItem.PriceCurrencyValue.SystemValue = newV.Item.PriceCurrencyValue.SystemValue;
                this.NewItem.PriceCurrencyValue.Value = math.multiply(+newV.Item.PriceCurrencyValue.Value, currentPriceCurrency ? +currentPriceCurrency.Rate : newV.Item.PriceCurrencyValue.Currency.Rate);
            } else {
                this.NewItem.PriceCurrencyValue.Currency = newV.Item.PriceCurrencyValue.Currency;
                this.NewItem.PriceCurrencyValue.CurrencyId = newV.Item.PriceCurrencyValue.CurrencyId;
                this.NewItem.PriceCurrencyValue.CurrencyValueId = newV.Item.PriceCurrencyValue.CurrencyValueId;
                this.NewItem.PriceCurrencyValue.Rate = currentPriceCurrency ? currentPriceCurrency.Rate : null;
				this.NewItem.PriceCurrencyValue.SystemValue = newV.Item.PriceCurrencyValue.SystemValue;
                this.NewItem.PriceCurrencyValue.Value = newV.Item.PriceCurrencyValue.Value;
            }
            if (this.isWeighingProduct) {
                setTimeout(() => {
                    this.NewItem.Quantity = 1;
                    this.$refs.WeighingProductTotal.focus();
                }, 200);
            } else {
                setTimeout(() => {
                    this.$refs.quantity.focus();
                }, 200);
            }

        }
    },
    watch: {
        'NewItem.Item'(newV) {
            if (newV)
                this.onChangeNewItemProduct(newV);
        }
    }
}

</script>

<style scoped>
.v-select >>> .vs__dropdown-menu {
    max-height: 350px !important;
}

.v-select >>> .vs__dropdown-menu::-webkit-scrollbar {
    background-color: #f8f9fa;
    border-radius: 9px;
    width: 5px;
    height: 8px;
}

.v-select >>> .vs__dropdown-menu::-webkit-scrollbar-thumb {
    background: rgb(193, 193, 193);
    border-radius: 9px;
    background-clip: content-box;
    cursor: pointer !important;
}

.b-dropdown >>> .dropdown-toggle {
    background: #202259;
    color: #fff;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
}

label {
    margin: 10px 0;
}

.w-15 {
    width: 15% !important;
}

button {
    padding: 7px 20px;
}
</style>

