import BaseService from "../base/base.service";
import axios from "axios";

class WarehouseItemSearchService extends BaseService {
    get modelName() {
        return "WarehouseItemSearch";
    }

    getAll({
               warehouseId,
               type,
               limit,
               query,
               sku,
               barcode,
           }) {
        let params = {
            warehouseId: warehouseId,
            type: type,
            q: (typeof (query) === "undefined" || query === null) ? "" : query,
            sku: (typeof (query) === "undefined" || sku === null) ? "" : sku,
            limit: limit,
            barcode: (typeof (barcode) === "undefined" || barcode === null) ? "" : barcode,
        };
        return axios.get(this.getAllUrl(), {params: params}).then(response => {
            return response.data;
        }, function (error) {
            return Promise.reject(error);
        })
    }
}

export default new WarehouseItemSearchService()
