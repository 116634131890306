<template>
    <v-select ref="select"
              style="min-width: 400px"
              class="bg-body bg-opacity-25 rounded"
              v-model="Client"
              @search="onSearch"
              :options="Clients"
              label="Name"
              :filterable="false"
              :select-on-tab="true"
              :disabled="disabled"
              @input="onInput"
              v-on:search:focus="onFocus"
              :placeholder='translation.translate("frequentlyRepeated","ViberiteKlienta")'
              v-on:search:blur="() => { $emit('blur') }"
    >
        <template slot="no-options">
            {{ translation.translate("frequentlyRepeated", "klientNeNayden") }}.
        </template>
        <template #option="{ Name, State }">
            <div class="d-flex justify-content-between">
                <span>{{ Name }}</span>
                <b-badge :variant="State < 0 ? 'danger' : 'success'">
                    <span>{{ State | decimal }}</span>
                </b-badge>
            </div>
        </template>
    </v-select>
</template>

<script>
import {debounce} from 'underscore';
import clientService from '../../services/client/client.service'
import vSelect from 'vue-select'
import ClientForm from "../../components/client/ClientForm";
import translate from "../../translation/translate";

export default {
    props: ['value', 'clearable', 'disabled'],
    name: "ClientSearchElement",
    components: {
        ClientForm,
        'v-select': vSelect
    },
    data() {
        return {
            vSelectHeight: null,
            translation: translate,
            Clients: [],
            Client: null,
            FocusInput: null,
            isAllowClear: false,
            specificKey: Math.random().toString(16).slice(2),
        };
    },
    methods: {
        onFocus() {
            let vm = this;
            setTimeout(() => {
                vm.$refs.select.$refs.search.focus();
            }, 50);
        },
        onInput(Client) {
            if (!Client)
                return this.$emit("input", null);
            if (Client.ClientId)
                return this.$emit("input", Client);
        },
        onSearch(search, loading, onFocus) {
            this.searchDebounce(loading, search, this, onFocus);
        },
        searchDebounce: debounce((loading, search, vm) => {
            if (search.length > 0) {
                loading();
                clientService.getAll({
                    query: search,
                    desc: false,
                    limit: 20,
                    skip: 0,
                    sort: "name"
                }).then((Clients) => {
                    vm.Clients = Clients;
                    loading();
                });
            }
        }, 300),
        focus() {
            this.$refs.select.open = true;
            setTimeout(() => {
                this.$refs.select.$refs.search.focus();
            }, 50);
        },
        clear() {
            this.Client = null;
        }
    },
    created() {
        this.Client = this.value;
        this.isAllowClear = typeof (this.clearable) === "boolean" ? this.clearable : this.isAllowClear;
        setTimeout(() => {
            this.$nextTick(() => {
                if (this.$refs.select)
                    this.vSelectHeight = this.$refs.select.$el.clientHeight;
            });
        }, 100);
    },
    watch: {
        value(v) {
            this.Client = v;
        },
        clearable(v) {
            this.isAllowClear = v;
        }
    }
}
</script>

<style scoped>
</style>
