<template>
    <b-row>
        <b-card @click="addNum(7)"
                class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
            7
        </b-card>
        <b-card @click="addNum(8)"
                class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
            8
        </b-card>
        <b-card @click="addNum(9)"
                class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
            9
        </b-card>
        <b-card @click="del()" class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
            <b-icon-backspace-fill/>
        </b-card>
        <b-card @click="addNum(4)"
                class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
            4
        </b-card>
        <b-card @click="addNum(5)"
                class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
            5
        </b-card>
        <b-card @click="addNum(6)"
                class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
            6
        </b-card>
        <b-card @click="clear()" class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
            C
        </b-card>
        <b-card @click="addNum(1)"
                class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
            1
        </b-card>
        <b-card @click="addNum(2)"
                class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
            2
        </b-card>
        <b-card @click="addNum(3)"
                class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
            3
        </b-card>
        <b-card @click="toNextField()"
                class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">Tab
        </b-card>
        <b-card @click="addNum(0)"
                class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">
            0
        </b-card>
        <b-card @click="addNum('00')"
                class="col-3 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center">00
        </b-card>
        <b-button @click="save"
                  class="col-6 font-weight-bold h5 m-0 p-1 cursor-pointer rounded-0 text-center my-primary"
                  :disabled="isSubmitted">
            <b-spinner v-if="isSubmitted" small></b-spinner>
            <span class="h5 text-white">{{ saveText }}</span>
        </b-button>
    </b-row>
</template>

<script>
export default {
    name: "Keyboard",
    data() {
        return {
            isSubmitted: false
        }
    },
    props: {
        saveText: {
            default: 'Оплатить',
            type: String
        }
    },
    methods: {
        addNum(key) {
            this.$emit('addNum', key)
        },
        toNextField() {
            this.$emit('toNextField')
        },
        clear() {
            this.$emit('clear')
        },
        del() {
            this.$emit('del')
        },
        save() {
            this.$emit('save');
        }
    },
    mounted() {
        window.addEventListener('sale-is-submitted', (event) => {
            this.isSubmitted = event.detail.isSubmitted;
        });
    }
}
</script>

<style scoped>

</style>